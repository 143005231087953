// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Componentes o páginas:
function LandingPage() {
  const APP_URL = process.env.REACT_APP_APP_URL || 'https://app.zifit.io';

  const redirectToApp = () => {
    window.location.href = APP_URL;
  };

  return (
    <div className="landing-page">
      <header className="header">
        <nav className="nav">
          <h1 className="logo">Zifit</h1>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <section className="hero">
          <h2>Welcome to Zifit</h2>
          <p>Your ultimate personal training platform. Join us and reach your fitness goals.</p>
          <button className="cta-button" onClick={redirectToApp}>Dashboard</button>
        </section>
      </header>

      <footer className="footer">
        <p>© 2024 Zifit. All rights reserved.</p>

        {/* Enlace a la política de privacidad */}
        <p>
          <a href="/privacy" style={{ color: 'white' }}>
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
}

function PrivacyPolicyPage() {
  const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@zifit.io';

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Política de Privacidad</h1>

      <p><strong>Última actualización:</strong> 1 de Enero de 2025</p>

      <p>
        Esta Política de Privacidad describe cómo Zifit (“nosotros”) maneja y protege la
        información personal que recopilamos sobre nuestros usuarios...
      </p>

      <h2>Información que recopilamos</h2>
      <p>
        Recopilamos información personal que el usuario nos proporciona directamente, como
        el nombre, correo electrónico y otra información que decida compartir...
      </p>

      <h2>Uso de la información</h2>
      <p>
        Utilizamos la información recopilada para proporcionar, mantener y mejorar nuestros
        servicios, comunicarnos con los usuarios y personalizar la experiencia...
      </p>

      <h2>Compartir la información</h2>
      <p>
        No vendemos ni alquilamos su información personal a terceros. Podemos compartir
        información con proveedores de servicios de terceros que nos ayudan a operar el
        servicio...
      </p>

      <h2>Seguridad</h2>
      <p>
        Implementamos medidas de seguridad razonables para proteger la información personal...
      </p>

      <h2>Cambios a esta política</h2>
      <p>
        Podemos actualizar esta Política de Privacidad ocasionalmente. Cualquier cambio
        será efectivo al publicar la nueva versión en esta página...
      </p>

      <h2>Contacto</h2>
      <p>
        Si tienes dudas o preguntas acerca de esta Política de Privacidad, puedes escribir a:
        <strong> {SUPPORT_EMAIL}</strong>.
      </p>
    </div>
  );
}

// App principal con React Router
function App() {
  return (
    <Router>
      <Routes>
        {/* Landing page en la ruta raíz */}
        <Route path="/" element={<LandingPage />} />
        {/* Página de política de privacidad en /privacy */}
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
